<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mx-auto" elevation="8">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                このページの使い方
              </v-list-item-title>

              <v-list-item-subtitle>
                <span v-if="canResearch"
                  >「授業満足度調査」は<router-link to="/timetable"
                    >時間割</router-link
                  >のページから行ってください。<br
                /></span>
                担任の先生から指示をされたすべての授業が「登録済み」または「時間割」に表示されない場合、こちらのページから改めて授業登録をしてください。
              </v-list-item-subtitle>

              <v-btn x-small rounded @click="toggleShow">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                詳細説明を{{ show ? "閉じる" : "開く" }}
              </v-btn>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text v-if="hasCoordinatorOrTeacher">
                    【講師のみなさんへ】
                    <p class="additional-message">
                      授業登録の操作方法については「OEポータル利用手順」をご参照ください。<br />
                      ※「OEポータル利用手順」は講師総会資料p.12のQRコードから閲覧することができます。
                    </p>
                  </v-card-text>
                  <v-card-text v-if="!hasCoordinatorOrTeacher">
                    【学生のみなさんへ】
                    <ol class="info-messages">
                      <li>
                        時間割に書かれている207から始まる7ケタの授業コード（半角数字）、または授業名を入力してください。
                      </li>
                      <li>
                        候補となる科目が表示されたら、<strong>クラス名と講師名を確認し</strong>オレンジ色の「登録」ボタンを押してください。
                      </li>
                      <li>
                        オレンジ色の「登録」ボタンを押すと、「CLASSROOM」ボタンが水色になります。「CLASSROOM」ボタンを押すとGoogle
                        Classroomのページが開きます。
                      </li>
                      <li>
                        青色の「クラスに参加」ボタンを押すと自動でGoogle
                        Classroomに登録されます。
                      </li>
                      <li>
                        一つの科目登録が終わったら<strong>ブラウザのタブを閉じて</strong>、このサイトに戻ってすべての科目を同様に登録してください。
                      </li>
                      <li>
                        登録が完了すると、メニューの「登録済み」と「時間割」に登録内容が表示されます。
                      </li>
                    </ol>
                    <p class="additional-message">
                      ※画面下に「更新」ボタンが表示された人はそのボタンをクリックしてから上記の操作を行ってください。<br />
                      ※誤って異なる科目に登録してしまった場合は、Google
                      Classroomのホーム画面から解除してください。詳細は<a
                        href="https://support.google.com/edu/classroom/answer/6069981?co=GENIE.Platform%3DiOS&hl=ja&oco=1"
                        target="_blank"
                        >こちら</a
                      >から。
                    </p>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Information",
  data: () => ({}),
  computed: {
    show() {
      return this.$store.state.detailShow;
    },
    canResearch() {
      return this.$store.state.canResearch;
    },
    hasCoordinatorOrTeacher() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator") {
        return true;
      } else if (userType === "teacher") {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    toggleShow() {
      this.$store.commit("TOGGLE_DETAIL_SHOW");
    }
  }
};
</script>
<style scoped>
.info-messages li {
  line-height: 1.5rem;
}
.additional-message {
  padding: 1rem 0 0 0;
}

.counterMaru ol {
  list-style: none;
}
.counterMaru li {
  list-style: none;
}
</style>
